import React from 'react';
import { applicationAttachmentsStyles } from './applicationAttachmentsStyles';
import { Carousel } from '@mantine/carousel';
import { ActionIcon, Badge, Card, Image, Text, ThemeIcon } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconFile } from '@tabler/icons';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { LocalStorageKeys, SessionStorageKeys } from '../../../components/LiitDrawer/LiitDrawer';
import { AttachmentListItem } from '../../../models/AttachmentListItem';
import { AttachmentDisplayFormat } from '../../../models/AttachmentDisplayFormat';
import { AttachmentType } from '../../../models/AttachmentType';
import { t } from 'ttag';

export type ApplicationAttachmentProps = {
  attachments: Array<AttachmentListItem>;
};

const PLACEHOLDER_IMAGE = '/img-placeholder.jpg';

export const ApplicationAttachments: React.FC<ApplicationAttachmentProps> = ({ attachments }) => {
  const { classes, theme } = applicationAttachmentsStyles();

  const [showAttachmentDrawer, setShowAttachmentDrawer] = useLocalStorage({
    key: LocalStorageKeys.ShowAttachmentDrawer,
    defaultValue: false,
  });

  const [, setActiveAttachment] = useSessionStorage<string>({
    key: SessionStorageKeys.ActiveImage,
  });

  const getAttachment = (attachment: AttachmentListItem): React.ReactNode => {
    const url =
      attachment.urls?.find((x) => x.format == AttachmentDisplayFormat.Thumbnail)?.url ??
      PLACEHOLDER_IMAGE;

    if (attachment.type == AttachmentType.Image) {
      return (
        <Carousel.Slide key={attachment.id} className={classes.centerCard}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              height={80}
              style={{ cursor: 'pointer' }}
              radius={8}
              src={url}
              w="auto"
              fit="contain"
              onClick={() => {
                setActiveAttachment(attachment.id as string);
              }}
            />
            <Text size={'xs'}>{attachment.fileName}</Text>
          </div>
        </Carousel.Slide>
      );
    } else if (attachment.type == AttachmentType.Pdf) {
      return (
        <Carousel.Slide key={attachment.id} className={classes.centerCard}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Card p={'xs'} shadow={'sm'} withBorder>
              <ActionIcon
                variant={'subtle'}
                color={theme.primaryColor}
                size={64}
                onClick={() => setActiveAttachment(attachment.id as string)}>
                <IconFile size={48} />
              </ActionIcon>
            </Card>
            <Text size={'xs'}>{attachment.fileName}</Text>
          </div>
        </Carousel.Slide>
      );
    }
  };

  return (
    <div>
      <div
        onClick={() => setShowAttachmentDrawer(!showAttachmentDrawer)}
        className={classes.attachmentsContainer}>
        {showAttachmentDrawer ? <IconChevronDown size={'16px'} /> : <IconChevronUp size={'16px'} />}
        <Text>{t`Bifogade filer`}</Text>
        <Badge color="orange">{attachments.length}</Badge>
      </div>
      {showAttachmentDrawer && (
        <Carousel
          withIndicators
          height={152}
          pl={24}
          pr={24}
          slideSize="33%"
          slideGap="md"
          breakpoints={[
            { maxWidth: 'md', slideSize: '10%' },
            { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
          ]}
          loop
          align="start">
          {attachments.map((a) => getAttachment(a))}
        </Carousel>
      )}
    </div>
  );
};
